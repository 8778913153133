  
const DemandProgramUpdateFormElements = [
    [
        {
            'label': 'Portfolio Name',
            'labelKey': 'portfolioId',
            'required': 'true',
            'type' : 'select',
        },
        {
            'label': 'Sales SPOC',
            'labelKey': 'salesSPOC',
            'required': 'true',
            'type' : 'input',
        },
    ],
    [
        {
            'label': 'Program Name',
            'labelKey': 'programId',
            'required': 'true',
            'type' : 'select',
        },
        {
            'label': 'Delivery SPOC',
            'labelKey': 'deliverySPOC',
            'required': 'true',
            'type' : 'input',
        },
    ],
    [
        {
            'label': 'Project Name',
            'labelKey': 'projectId',
            'required': 'true',
            'type' : 'select',
        },
        {
            'label': 'Talent SPOC',
            'labelKey': 'talentSPOC',
            'required': 'true',
            'type' : 'input',
        },
    ],
];

export default DemandProgramUpdateFormElements;