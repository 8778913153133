import React, { createContext } from 'react';

// Create the context
const AppConfigContext = createContext();

// Configuration object
const AppConfig = {
  ATS_BASE_API_URL: process.env.ATS_BASE_API_URL,
  ATS_API_HOST: "https://www.ingrainsys.in",
  ATS_API_PORT: "8081",
  //ATS_API_PORT: "8081",
  //ATS_API_HOST: "https://shark-app-yiehi.ondigitalocean.app",
  //ATS_API_PORT: "443",
  ATS_API_ENDPOINT: "/api/v1",
};

// Provider component
function AppConfigProvider({ children }) {
  return (
    <AppConfigContext.Provider value={AppConfig}>
      {children}
    </AppConfigContext.Provider>
  );
}

export { AppConfig, AppConfigContext, AppConfigProvider };
