import WebTable from "../../html/WebTableDrill";
import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'; 

import DeleteModal from "./ProfileDeleteModal";
import UpdateModal from "./ProfileUpdateModal";

import { useAuthContext } from '../../config/AuthProvider.js';
import Loading from "../../../pages/components/Loading.js";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { AppConfigContext } from "../../config/AppConfig";

import axios from 'axios';

const ProfileList = ({updateHandler, data}) => {

    const [selectedId, setSelectedId] = useState(null);
    const [isDeleteActionPopupVisible, setIsDeleteActionPopupVisible] = useState(false);
    const [isUpdateActionPopupVisible, setIsUpdateActionPopupVisible] = useState(false);

    const AppConfig = useContext(AppConfigContext);
	const URLLink = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
    const ProfileDownloadAPIEndPoint = `${URLLink}/profiledownload/`;
    const FileDownload = require("js-file-download");
    const { accessToken } = useAuthContext();

    const handleDownload = async (rowData) => {

        console.log("Handle Download: " + rowData.profileId)
        console.log(rowData);
        setSelectedId(rowData.profileId);

        try {
            const urlLink = ProfileDownloadAPIEndPoint + rowData.profileId;
            axios({
                url: urlLink,
                method: 'GET',
                responseType: 'blob', // important
                headers: {
                'Authorization': `Bearer ${accessToken}`,
              }
            }).then((response) => {
                // create file link in browser's memory
                FileDownload(response.data, rowData.fileName); 
            });
          } catch (error) {
            console.error('Error downloading file:', error);
          } 
    };

    const handleUpdate = (rowData) => {
        console.log("Handle Update : " + rowData.profileId)
        setSelectedId(rowData.profileId);
        setIsUpdateActionPopupVisible(true);
    };

    const handleDelete = (rowData) => {
        console.log("Handle Delete : " + rowData.profileId)
        setSelectedId(rowData.profileId);
        setIsDeleteActionPopupVisible(true);
    };


    const columns = {
        "firstName" : "First Name",
        "lastName" : "Last Name",
        "emailId" : "Email ID",
    };

    const actions = [
       {
            'action' : 'download',
            'icon' : <FontAwesomeIcon icon={faDownload} />,
            'param' : 'profileId',
            'handler' : handleDownload,
       },
       {
            'action' : 'edit',
            'icon' : <FontAwesomeIcon icon={faEdit} />,
            'param' : 'profileId',
            'handler' : handleUpdate,
       },
       {
            'action' : 'Delete',
            'icon' : <FontAwesomeIcon icon={faTrash} />,
            'param' : 'profileId',
            'handler' : handleDelete,
       } 
    ];


    return (
        <div>
            <WebTable
                columns={columns}
                data={data}
                rowActions={actions}
                onSort={true}
                onSearch={true}
                onPageNavigation={true}
            />
            <DeleteModal
                isVisible={isDeleteActionPopupVisible}
                setIsVisible={setIsDeleteActionPopupVisible}
                Id={selectedId}
                onFresh={updateHandler}
            />
            <UpdateModal
                isVisible={isUpdateActionPopupVisible}
                setIsVisible={setIsUpdateActionPopupVisible}
                Id={selectedId}
                onFresh={updateHandler}
            />
        </div>
    );
}

export default withAuthenticationRequired(ProfileList, {
    onRedirecting: () => <Loading />,
  });