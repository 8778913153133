import React, { useEffect, useContext, useState } from 'react';
import DataService from '../../DataService';
import WebForm from '../../html/WebForm';
import UpdateFormElements from './FulfilmentUpdateFormElements';
import { AppConfigContext } from '../../config/AppConfig';

import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";


const FulfilmentUpdateModal = ({ isVisible, setIsVisible, Id, Title, statusList, subStatusList, fulfilmentId, onFresh }) => {

  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

  const [modalData, setModalData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState();

  const APIEndPoint = `${URL}/fulfilmentwf/`;
  const {accessToken} = useAuthContext();

  const preFormSubmission = (data) => {
    console.log("preFormSubmission");
    data.fulfilmentId = `${fulfilmentId}`;
};

const postFormSubmission = (response) => {
    console.log("postFormSubmission");
    onFresh();
}

const Form = {
    'title' : 'Update Applicant Status',
    'restAPI' : APIEndPoint,
    'apiMethod' : 'PUT',
    'elements' : UpdateFormElements,
    'preHandler' : preFormSubmission,
    'postHandler' : postFormSubmission
};

  const setDependentOn = (value) => {
    console.log("Callback dependent list for value " + value);
    console.log(subStatusList[value]);

    UpdateFormElements[2][1] = {
      ...UpdateFormElements[2][1],
      "options": subStatusList[value],
    };

    setSelectedStatus(value);
  }

  useEffect(() => {
    const fetchModalData = async () => {
      try {
          if (Id && fulfilmentId && isVisible) {
              const data = await DataService.getById(`${APIEndPoint}${fulfilmentId}`, accessToken);
              console.log("fetchModalData Response for id " + fulfilmentId);
              setModalData(data);

              console.log("statusList ", statusList);
              console.log("subStatusList ", subStatusList);

            UpdateFormElements[0][0] = {
              ...UpdateFormElements[0][0],
              "labelValue": Id,
            };

            UpdateFormElements[0][1] = {
              ...UpdateFormElements[0][1],
              "labelValue": Title,
            };

            UpdateFormElements[1][0] = {
              ...UpdateFormElements[1][0],
              "labelValue": data.profileId,
            };

            UpdateFormElements[1][1] = {
              ...UpdateFormElements[1][1],
              "labelValue": data.profileName,
            };

            if(statusList) {
              UpdateFormElements[2][0] = {
                ...UpdateFormElements[2][0],
                "options" : statusList,
                "callback" : setDependentOn,
              };
            }

            if (subStatusList) {
              if (statusList && statusList.length > 0) {
                let firstElement = data.fulfilmentStatusId;
        
                UpdateFormElements[2][1] = {
                  ...UpdateFormElements[2][1],
                  "options": subStatusList[`${firstElement}`],
                };
              }
            }

          }
      } catch (error) {
        console.error(error);
      }
    };

    fetchModalData();
  }, [Id,fulfilmentId,isVisible]);


  return (
      modalData && isVisible ? (
        <WebForm
            id={fulfilmentId}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            form={Form}
            formData={modalData}
            setFormData={setModalData}
        /> 
      ) :  null
  );
};

export default withAuthenticationRequired(FulfilmentUpdateModal, {
  onRedirecting: () => <Loading />,
});