import React, { useEffect, useMemo, useState } from 'react';

const WebSelect = ({ register, WebObj, setValue, defaultValue }) => {
  const { labelKey, options, callback, required } = WebObj; // Destructure for readability

  const handleDependentChange = (event) => {

    const selectedValue = event.target.value;
    console.log("Selected Option value is " + selectedValue);
    setValue(WebObj.labelKey, selectedValue);
    callback && callback(selectedValue) ;
  };

  const selectOptions = useMemo(() => {

    //console.log("Default Value for " + WebObj.labelKey + " is " + defaultValue);
    if (!options) return null; // Early return if no options

    return options.map((option) => (
      <option key={option.value} value={option.value} selected={`${defaultValue}` === `${option.value}`}>
        {option.label}
      </option>
    ));
  }, [options, defaultValue]); // Re-create options only on options or defaultValue change

  return (
    <select 
      style={{ width: '100%' }} 
      {...register(labelKey, 
      { required: required === 'true' })} 
      onChange={handleDependentChange}
      id={labelKey} 
      name={labelKey}>
      {selectOptions}
    </select>
  );
};

export default WebSelect;