import React, { useEffect, useContext, useState } from 'react';
import DataService from '../../DataService';
import UpdateFormElements from "./DemandUpdateFormElements"
import { AppConfigContext } from '../../config/AppConfig';
import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";


import WebForm from '../../html/WebForm';

const DemandUpdateModal = ({ isVisible, setIsVisible, Id, clientList, statusList, onFresh }) => {

  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

  const [demandData, setDemandData] = useState(null);
  const APIEndPoint = `${URL}/demand/`;
  const {accessToken} = useAuthContext();

  const preFormSubmission = (data) => {
    console.log("preFormSubmission");

    if(data.startDate) {
      const selectedDate = data.startDate;
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
      const day = selectedDate.getDate().toString().padStart(2, '0');
      const year = selectedDate.getFullYear();
      data.startDate = `${year}-${month}-${day}`;
    }
};

const postFormSubmission = (response) => {
    console.log("postFormSubmission");
    onFresh();
}

const Form = {
    'title' : 'Update Demand',
    'restAPI' : APIEndPoint,
    'apiMethod' : 'PUT',
    'elements' : UpdateFormElements,
    'preHandler' : preFormSubmission,
    'postHandler' : postFormSubmission
};

  useEffect(() => {
    const fetchData = async () => {
      try {
          if (Id && isVisible) {
              const data = await DataService.getById(APIEndPoint + Id, accessToken);
              console.log("fetchData Response for id " + Id);
              setDemandData(data);

              if (clientList && clientList.length > 0) {
                UpdateFormElements[1][0] = {
                  'label': 'Client Name',
                  'labelKey': 'clientId',
                  'required': 'true',
                  'type': 'select',
                  'options': clientList,
                };
              } else {
                console.log("DemandUpdateModal - Client List is empty");
              }  

              if (statusList && statusList.length > 0) {
                UpdateFormElements[3][0] = {
                  'label': 'Demand Status',
                  'labelKey': 'demandStatus',
                  'required': 'true',
                  'type': 'select',
                  'options': statusList,
                } 
              } else {
                  console.log("DemandUpdateModal - Status List is empty")
              }
          }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [Id,isVisible]);


  return (
      demandData && isVisible ? (
        <WebForm
            id={Id}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            form={Form}
            formData={demandData}
            setFormData={setDemandData}
        /> 
      ) :  null
  );
};

export default withAuthenticationRequired(DemandUpdateModal, {
  onRedirecting: () => <Loading />,
});