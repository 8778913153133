import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import './FacetedSearch.css'; 

import { useForm } from 'react-hook-form';

import SortMenu from './SortMenu';
import DataStats from './Stats.js';
import FacetedSearchFilters from './Filters.js';
import FacetedSearchTableResults from './FacetedSearchTableResults';
import FacetedSearchAggregateResults from './FacetedSearchAggregateResults';

const FacetedSearch = ({ data, statusList, config }) => {

  const { register, handleSubmit, reset, watch, setValue } = useForm();
  const [dataSet, setDataSet] = useState(data); 
  const [filteredDataSet, setFilteredDataSet] = useState(data); 
  const [sortOption, setSortOption] = useState(config.sort.defaultField);
  const [sortOrder, setSortOrder] = useState(config.sort.defaultOrder);
  const [Stats, setStats] = useState({});
  const [viewType, setViewType] = useState('TABLE');
  const [searchKeyword, setSearchKeyword] = useState(''); 

  const onSubmit = (data) => {
    const filtered = dataSet.filter((item) => {
      return config.filters.every((filterField) => {
        const selectedValues = data[filterField]; 
        return !selectedValues || selectedValues.length === 0 || selectedValues.includes(item[filterField]);
      });
    });

    console.log("Filtered Dataset is ", filtered);
    setFilteredDataSet(filtered);
  };

  useEffect(() => {
    const stats = {};
    config.stats.forEach((statField) => {
      stats[statField] = {};
      filteredDataSet.forEach((item) => { 
        if (item[statField] !== null) { 
          stats[statField][item[statField]] = (stats[statField][item[statField]] || 0) + 1;
        }
      });
    });
    setStats(stats);
  }, [filteredDataSet]); 


  useEffect(() => {
    const filtered = dataSet.filter((item) => { 
      return config.filters.every((filterField) => {
        const selectedValues = watch(filterField); 
        return !selectedValues || selectedValues.length === 0 || selectedValues.includes(item[filterField]);
      });
    });

    // Filter by search keyword
    const filteredBySearch = filtered.filter((item) => {
      return config.search.some((field) => {
        const itemValue = item[field]?.toString()?.toLowerCase(); 
        return itemValue && itemValue.includes(searchKeyword.toLowerCase());
      });
    });

    // Sort results based on config
    const sortedDataSet = [...filteredBySearch];
    const sortField = config.sort.fields[sortOption];
    const order = sortOrder === 'Asc' ? 1 : -1;

    sortedDataSet.sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];

      return order * aValue?.localeCompare(bValue);
    });
    setFilteredDataSet(sortedDataSet);
  }, [watch, sortOption, sortOrder, dataSet, searchKeyword]); 

  const handleViewChange = (view) => {
    setViewType(view);
  };

  const handleSearchInputChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  return (
    <Container fluid className="p-4">
      <Row>
      <Col xs={2} >
          <div className="searchheader">
            <h3>{config.title}</h3>
          </div>
      </Col>
        <Col xs={10}>
          <div className="searchheader">
            <div className="d-flex justtify-content-begining">
              <Form className="ms-2">
                <Form.Control 
                  type="text" 
                  placeholder="Search" 
                  value={searchKeyword} 
                  style={{ width: '300px' }}
                  onChange={handleSearchInputChange} 
                />
              </Form>
            </div>
            <div className="d-flex justify-content-end"> 
                <SortMenu
                  sortFields={config.sort.fields}
                  sortOption={sortOption} 
                  setOption={setSortOption}
                  sortOrder={sortOrder} 
                  setOrder={setSortOrder}
                />
                &nbsp;
                <button className={`btn btn-primary ${viewType === 'TABLE' ? 'active' : ''}`} onClick={() => handleViewChange('TABLE')}>Table View</button>
                &nbsp;
                <button className={`btn btn-primary ${viewType === 'GRID' ? 'active' : ''}`} onClick={() => handleViewChange('GRID')}>Aggregate View</button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={2} >
          <FacetedSearchFilters
            register={register}
            watch={watch}
            handleSubmit={handleSubmit(onSubmit)}
            reset={reset}
            filterConfig={config.filters}
            dataSet={dataSet} 
          />
        </Col>
        <Col xs={10}>
          <Row>
            <Col xs={12}>
              <DataStats stats={Stats} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {viewType === 'TABLE' && 
                <FacetedSearchTableResults 
                  filteredDataSet={filteredDataSet} 
                  config={config}
                  />}
              {viewType === 'GRID' && 
                <FacetedSearchAggregateResults 
                  filteredDataSet={filteredDataSet} 
                  config={config}
                  groupStatusList={statusList}
                  indexCol={config.indexCol}
                  groupCol={config.groupCol}
                  gridColumns={config.gridColumns}
                  gridTableColumns={config.gridTableColumns}
                />
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FacetedSearch;