import React, { useState, useRef } from 'react';
import { Card, Row, Col, Table, Modal } from 'react-bootstrap';

const AggregateViewCard = ({ data, indexCol, groupCol, gridColumns, gridTableColumns, groupStatusList }) => {
  const {
    demandId,
    fulfilmentId,
    title,
    role,
    location,
    positions,
    startDate,
    requestedDate,
    demandStatus,
    clientName,
    firstName,
    lastName,
    emailId,
  } = data;

  const [showDetails, setShowDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const collapseRefs = useRef([]);

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const formattedRequestedDate = new Date(requestedDate).toLocaleDateString();

  // Calculate fulfilmentStatusCounts
  const statusCounts = groupStatusList.reduce((acc, status) => {
    acc[status.label] = data.groupStatusCounts?.[status.label] || 0;
    return acc;
  }, {});

  // Calculate total candidates
  const totalCandidates = Object.values(statusCounts).reduce((a, b) => a + b, 0);

  const renderHeaderCells = (data,columns) => {
    return columns.map((col) => (
      <th key={col.prop} style={{ whiteSpace: 'nowrap' }}>
          {col.prefix} {data[col.prop]} {col.suffix}  
      </th>
    ));
  };

  return (
    <Card>
      <Card.Header>
        {renderHeaderCells(data, gridColumns.headerColumns)}
      </Card.Header>
      <Card.Body>
        <Row>
          {/* Render data columns in groups of 3 */}
          {gridColumns.dataColumns.map((col, index) => (
            <Col xs={4} key={index}>
              <b>{col.label}:</b> {data[col.prop]}
            </Col>
          ))}
        </Row>
        <hr />

        {/* Display fulfilmentStatus counts */}
        <Row>
          <Col xs={12}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              {groupStatusList.map((status, index) => (
                <div
                  key={status.value}
                  style={{
                    border: '1px solid #ccc',
                    padding: '5px',
                    margin: '5px',
                    textAlign: 'center',
                    flex: 1,
                  }}
                >
                  <a href="#" onClick={() => handleStatusClick(status)}>
                    <div>
                      <h3>{statusCounts[status.label]}</h3>
                    </div>
                  </a>
                  <div>
                    <h5>{status.label}</h5>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
        {/* Modal for displaying details */}
        <Modal 
                show={showModal}  
                size="lg" 
                style={{ maxHeight: '60vh', overflowY: 'auto' }}
                onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>
                {clientName} : #{demandId} {title} ({demandStatus})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  {gridTableColumns.map((col) => (
                    <th style={{ whiteSpace: 'nowrap' }} key={col.prop}>{col.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* Check if groupedEntries exist for the selected status */}
                {data.groupedEntries && data.groupedEntries[selectedStatus?.label]?.length > 0 ? (
                  data.groupedEntries[selectedStatus?.label].map((item) => (
                    <tr key={item.fulfilmentId}>
                      {gridTableColumns.map((col) => (
                        <td style={{ whiteSpace: 'nowrap' }} key={col.prop}>{item[col.prop]}</td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td style={{ whiteSpace: 'nowrap' }} colSpan={gridTableColumns.length}>No records found for this status</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      </Card.Body>
    </Card>
  );
};

export default AggregateViewCard;