import WebTable from "../../html/WebTableDrill";
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';   

import ProjectNewModal from "./ProjectNewModal";
import DeleteModal from "./ProjectDeleteModal"
import UpdateModal from "./ProjectUpdateModal";

const ProjectList = ({ProgramId, updateHandler, data}) => {

    const [selectedProgramId, setSelectedProgramId] = useState(null);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [isDeleteActionPopupVisible, setIsDeleteActionPopupVisible] = useState(false);
    const [isUpdateActionPopupVisible, setIsUpdateActionPopupVisible] = useState(false);

    const handleUpdate = (rowData) => {
        console.log("Handle Program Update : " + rowData.projectId)
        setSelectedProgramId(ProgramId);
        setSelectedProjectId(rowData.projectId);
        setIsUpdateActionPopupVisible(true);
    };

    const handleDelete = (rowData) => {
        console.log("Handle Program Delete : " + ProgramId + " " + rowData.projectId)
        setSelectedProgramId(ProgramId);
        setSelectedProjectId(rowData.projectId);
        setIsDeleteActionPopupVisible(true);
    };


    const columns = {
        "projectName" : "Project Name",
        "spocName" : "SPOC Name",
    };

    const headerActions = [
        {
            'action' : '+ New Project',
            'jsxObject'  : <ProjectNewModal ProgramId={ProgramId} updateHandler={updateHandler} />
        },
    ];

    const rowActions = [
       {
            'action' : 'edit',
            'icon' : <FontAwesomeIcon icon={faEdit} />,
            'param' : 'projectId',
            'handler' : handleUpdate,
       },
       {
            'action' : 'Delete',
            'icon' : <FontAwesomeIcon icon={faTrash} />,
            'param' : 'projectId',
            'handler' : handleDelete,
       } 
    ];


    return (
        <div id="projectlist">
            <WebTable
                columns={columns}
                data={data}
                rowActions={rowActions}
                headerActions={headerActions}
            />
            <DeleteModal
                isVisible={isDeleteActionPopupVisible}
                setIsVisible={setIsDeleteActionPopupVisible}
                ProgramId={selectedProgramId}
                ProjectId={selectedProjectId}
                onFresh={updateHandler}
            />
            <UpdateModal
                isVisible={isUpdateActionPopupVisible}
                setIsVisible={setIsUpdateActionPopupVisible}
                ProgramId={selectedProgramId}
                ProjectId={selectedProjectId}
                onFresh={updateHandler}
            />
        </div>
    );
}

export default ProjectList;