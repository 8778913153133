import WebTable from "../../html/WebTableDrill";
import React, { useState,useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit } from '@fortawesome/free-solid-svg-icons';   

import FulfilmentNewModal from "./FulfilmentNewModal";
import UpdateModal from "./FulfilmentUpdateModal";

import { useAuthContext } from '../../config/AuthProvider.js';
import Loading from "../../../pages/components/Loading.js";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { AppConfigContext } from "../../config/AppConfig";

import axios from 'axios';

const FulfilmentList = ({Id, Title, statusList, subStatusList, updateHandler, data}) => {

    const [selectedId, setSelectedId] = useState(null);
    const [demandTitle, setDemandTitle] = useState(null);
    const [selectedFulfilmentId, setSelectedFulfilmentId] = useState(null);
    const [isUpdateActionPopupVisible, setIsUpdateActionPopupVisible] = useState(false);

    const AppConfig = useContext(AppConfigContext);
	const URLLink = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
    const ProfileDownloadAPIEndPoint = `${URLLink}/profiledownload/`;
    const FileDownload = require("js-file-download");
    const { accessToken } = useAuthContext();

    const handleDownload = async (rowData) => {

        console.log("Handle Download: " + rowData.profileId)
        console.log(rowData);

        try {
            const urlLink = ProfileDownloadAPIEndPoint + rowData.profileId;
            axios({
                url: urlLink,
                method: 'GET',
                responseType: 'blob', // important
                headers: {
                'Authorization': `Bearer ${accessToken}`,
              }
            }).then((response) => {
                // create file link in browser's memory
                FileDownload(response.data, rowData.fileName); 
            });
          } catch (error) {
            console.error('Error downloading file:', error);
          } 
    };

    const handleUpdate = (rowData) => {
        console.log("Handle Fulfilment Workflow Update for Dmenad : " + Id + " with Fulfilment Id " + rowData.fulfilmentId)
        setSelectedId(Id);
        setDemandTitle(Title);
        setSelectedFulfilmentId(rowData.fulfilmentId);
        setIsUpdateActionPopupVisible(true);
    };

    const columns = {
        "profileName" : "Applicant Name",
        "emailId" : "Email ID",
        "fulfilmentStatus" : "Fulfilment Status",
        "fulfilmentSubStatus" : "Sub Status",
        "updatedBy" : "Updated By",
    };

    const headerActions = [
        {
            'action' : '+ Propose Profile',
            'jsxObject'  : <FulfilmentNewModal 
                                Id={Id} 
                                Title={Title} 
                                statusList={statusList}
                                subStatusList={subStatusList}
                                updateHandler={updateHandler}/>
        },
    ];

    const rowActions = [
        {
            'action' : 'download',
            'icon' : <FontAwesomeIcon icon={faDownload} />,
            'param' : 'profileId',
            'handler' : handleDownload,
       },
       {
            'action' : 'edit',
            'icon' : <FontAwesomeIcon icon={faEdit} />,
            'param' : 'fulfilmentId',
            'handler' : handleUpdate,
       },
    ];


    return (
        <div id="fulfilmentwflist">
            <WebTable
                columns={columns}
                data={data}
                rowActions={rowActions}
                headerActions={headerActions}
            />
            <UpdateModal
                isVisible={isUpdateActionPopupVisible}
                setIsVisible={setIsUpdateActionPopupVisible}
                Id={selectedId}
                Title={demandTitle}
                statusList={statusList}
                subStatusList={subStatusList}
                fulfilmentId={selectedFulfilmentId}
                onFresh={updateHandler}
            />
        </div>
    );
}

export default withAuthenticationRequired(FulfilmentList, {
    onRedirecting: () => <Loading />,
  });