import { AppConfig } from "../config/AppConfig";
import DataService from "../DataService";

class PortfolioDataService {

  getAllProjects (records) {

    if (records) {

      let RecordList = {};
      let uniqueList = []; // to eliminate duplicates

      records.forEach(record => {

        if (!uniqueList.includes(record.projectId)) {

          uniqueList.push(record.projectId);
          if (!RecordList[record.programId]) {
            RecordList[record.programId] = [];
          }

          RecordList[record.programId].push({
            "value": record.projectId,
            "label": record.projectName
          });
        }
      });

      return RecordList;

    }

    return null; // Or return an empty array/placeholder if no keyword
  }

  getAllPrograms(records) {

    if (records) {

      let RecordList = {};
      let uniqueList = []; // to eliminate duplicates

      records.forEach(record => {

        if (!uniqueList.includes(record.programId)) {
          uniqueList.push(record.programId);

          if (!RecordList[record.portfolioId]) {
            RecordList[record.portfolioId] = [];
          }

          RecordList[record.portfolioId].push({
            "value": record.programId,
            "label": record.programName
          });
        }
      });

      return RecordList;
    }
    return null; // Or return an empty array/placeholder if no keyword
  }

  getAllPortfolio(records) {

    if (records) {
      let RecordList = [] ;
      let uniqueList = []; // to eliminate duplicates

      records.forEach(record => {
        if (!uniqueList.includes(record.portfolioId)) {
          uniqueList.push(record.portfolioId);
          RecordList.push({
            "value": record.portfolioId,
            "label": record.portfolioName
          });
        }
      });
      return RecordList;
    }

    return null;
  }

}

export default new PortfolioDataService();