import { useEffect } from "react";

const WebInput = ({register, WebObj, defaultValue})  => {

    useEffect(() => {
        //console.log("WebInput useEffect " + WebObj.labelKey + " " + defaultValue)
    },[]);
    return (
            <input
                style={{ width: '100%' }}
                type="text"
                {...register(WebObj.labelKey , { required: WebObj.required === 'true' })}
                id={WebObj.labelKey}
                name={WebObj.labelKey}
                defaultValue={defaultValue}
            />
    );
};

export default WebInput;