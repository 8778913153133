import WebTable from "../../html/WebTableDrill";
import React, { useState,useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faProjectDiagram, faCog  } from '@fortawesome/free-solid-svg-icons'; 

import DeleteModal from "./DemandDeleteModal";
import UpdateModal from "./DemandUpdateModal";
import DemandDetailsModal from "./DemandDetailsModal";
import DemandProgramUpdateModal from "./DemandProgramUpdateModal";
import DataService from "../../DataService";
import PortfolioDataService from "../../data/PortfolioDataService";

import { AppConfigContext } from '../../config/AppConfig';
import { useAuthContext } from '../../config/AuthProvider';

const DemandList = ({updateHandler, data, clientList, statusList}) => {

    const [selectedId, setSelectedId] = useState(null);
    const [clientPortfolioList, setClientPortfolioList] = useState();
    const [clientProgramList, setClientProgramList] = useState();
    const [clientProjectList, setClientProjectList] = useState();
    const [demandData, setDemandData] = useState();

    const [isDeleteActionPopupVisible, setIsDeleteActionPopupVisible] = useState(false);
    const [isUpdateActionPopupVisible, setIsUpdateActionPopupVisible] = useState(false);
    const [isDemandDetailsActionPopupVisible, setIsDemandDetailsActionPopupVisible] = useState(false);
    const [isDemandProgramUpdateActionPopupVisible, setIsDemandProgramUpdateActionPopupVisible] = useState(false);

    const AppConfig = useContext(AppConfigContext);
    const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;
    const ClientPfAPIEndPoint = `${URL}/projects/`;
    const {accessToken} = useAuthContext();

    const handleDemandProgramUpdate = async (rowData) => {

        console.log("Handle Demand Program Update : " + rowData.demandId)
        console.log(rowData);
        let clientId = rowData.clientId;
        try {
            const response = await DataService.getAll(`${ClientPfAPIEndPoint}${clientId}`, accessToken);
            const pfList = PortfolioDataService.getAllPortfolio(response.data);
            const pgmList = PortfolioDataService.getAllPrograms(response.data);
            const prList = PortfolioDataService.getAllProjects(response.data);

            setClientPortfolioList(pfList);
            setClientProgramList(pgmList);
            setClientProjectList(prList);
        } catch (error) {
            console.log("Unable to get Client Portfolio list details for client Id ", clientId);
        }

        setDemandData(rowData);
        setSelectedId(rowData.demandId);
        setIsDemandProgramUpdateActionPopupVisible(true);
    };

    const handleDemandDetails = (rowData) => {
        console.log("Handle Demand Details Update : " + rowData.demandId)
        setSelectedId(rowData.demandId);
        setIsDemandDetailsActionPopupVisible(true);
    };

    const handleUpdate = (rowData) => {
        console.log("Handle Update : " + rowData.demandId)
        setSelectedId(rowData.demandId);
        setIsUpdateActionPopupVisible(true);
    };

    const handleDelete = (rowData) => {
        console.log("Handle Delete : " + rowData.demandId)
        setSelectedId(rowData.demandId);
        setIsDeleteActionPopupVisible(true);
    };


    const columns = {
        "clientName" : "Client",
        "title" : "Demand",
        "location": "Location",
        "demandStatus": "Status",
        "positions" : "Pos"
    };

    const actions = [
       {
            'action' : 'edit',
            'icon' : <FontAwesomeIcon icon={faEdit} />,
            'param' : 'demandId',
            'handler' : handleUpdate,
       },
       {
            'action' : 'details',
            'icon' : <FontAwesomeIcon icon={faCog} />,
            'param' : 'demandId',
            'handler' : handleDemandDetails,
       },
       {
            'action' : 'portfolio',
            'icon' : <FontAwesomeIcon icon={faProjectDiagram} />,
            'param' : 'demandId',
            'handler' : handleDemandProgramUpdate,
       },
       {
            'action' : 'Delete',
            'icon' : <FontAwesomeIcon icon={faTrash} />,
            'param' : 'demandId',
            'handler' : handleDelete,
       } 
    ];

    return (
        <div key="webtable">
            <WebTable
                columns={columns}
                data={data}
                rowActions={actions}
                onSort={true}
                onSearch={true}
                onPageNavigation={true}
            />

            <DemandDetailsModal 
                isVisible={isDemandDetailsActionPopupVisible}
                setIsVisible={setIsDemandDetailsActionPopupVisible}
                Id={selectedId}
                onFresh={updateHandler}
            />

            <DemandProgramUpdateModal
                isVisible={isDemandProgramUpdateActionPopupVisible}
                setIsVisible={setIsDemandProgramUpdateActionPopupVisible}
                Id={selectedId}
                onFresh={updateHandler}
                demandData={demandData}
                portfolioList={clientPortfolioList}
                programList={clientProgramList}
                projectList={clientProjectList}
            />

            <DeleteModal
                isVisible={isDeleteActionPopupVisible}
                setIsVisible={setIsDeleteActionPopupVisible}
                Id={selectedId}
                onFresh={updateHandler}
            />
            <UpdateModal
                isVisible={isUpdateActionPopupVisible}
                setIsVisible={setIsUpdateActionPopupVisible}
                Id={selectedId}
                onFresh={updateHandler}
                clientList={clientList}
                statusList={statusList}
            />
        </div>
    );
}

export default DemandList;