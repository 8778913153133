import React from 'react';

const DataStats = ({ stats }) => {
  //const colors = ['#007bff', '#ffc107', '#663399', '#28a745', '#dc3545'];
  const colors = [
    '#FFA500', // Orange
    '#FFC300', // Gold
    '#F08000', // Tangerine
    '#FF7F50', // Coral
    '#FF9933', // Amber
    '#FF8C00', // Dark Orange
    '#FFA07A', // Apricot
  ];

  return (
    <div className="stats">
      {stats && 
        Object.entries(stats).map(([statField, counts]) => (
            <div key={statField} className="stat-group">
            <div>
              {statField && counts && 
                Object.entries(counts).map(([value, count], index) => (
                  <div
                    key={`${statField}-${value}`}
                    className="stat-item"
                    style={{ backgroundColor: colors[index % colors.length] }}
                  >
                    <span>{value}</span>
                    <span>({count})</span>
                  </div>
                ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default DataStats;