import React, { useEffect, useContext, useState, useRef } from 'react';
import DataService from '../../DataService';
import { AppConfigContext } from '../../config/AppConfig';
import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";

import WebForm from '../../html/WebForm';

const DemandDetailsModal = ({ isVisible, setIsVisible, Id, onFresh }) => {

  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

  const [demandData, setDemandData] = useState(null);
  const [formData, setFormData] = useState(null);

  const APIEndPoint = `${URL}/demandparams/`;
  const {accessToken} = useAuthContext();

  const preFormSubmission = (data) => {
    console.log("preFormSubmission");
    console.log(data);

    const transformedData = Object.entries(data)
    .filter(([key, _]) => key.startsWith('val_'))
    .map(([key, value], index) => {
        const keyId = parseInt(key.replace('val_', ''));
        const detailKey = data[`key_${keyId}`];
        return {
            detailKeyId: `${keyId}`,
            detailKey: `${detailKey}`,
            detailValue: `${value}`
        };
    });

    console.log("Transformed Data");
    console.log(transformedData);
    return transformedData ;

};

const postFormSubmission = (response) => {
    console.log("postFormSubmission");
    onFresh();
}

const formRef = useRef({
  title: 'Update Demand Info',
  restAPI: APIEndPoint,
  preHandler: preFormSubmission,
  postHandler: postFormSubmission,
  elements: [], // Initially empty array
});

let Form = {
    'title' : 'Update Demand Info',
    'restAPI' : APIEndPoint,
    'preHandler' : preFormSubmission,
    'postHandler' : postFormSubmission
};

  function findDemandDetailValue(dataArray, detailKeyId) {
    // Find the object in the array where detailKeyId matches
    const matchingObject = dataArray.find(item => item.detailKeyId === detailKeyId);

    // If a match is found, return the detailValue
    if (matchingObject) {
      return matchingObject.detailValue;
    } else {
      // Return a default value or handle the case where no match is found
      return null; // Or any other appropriate default value
    }
  }

  useEffect(() => {

    const fetchData = async () => {

      try {

        if (Id && isVisible) {

          let demandDetailsData = [];

          try {
            const demandDetailsResponse = await DataService.getAll(APIEndPoint + Id, accessToken);
            console.log("Demand Paarsms Data Response for id " + Id);
            console.log(demandDetailsResponse);
            demandDetailsData = demandDetailsResponse.data;
          } catch (error) {
            console.error(error);
          }

          const response = await DataService.getAll(APIEndPoint, accessToken);
          const demandParams = response.data;
          console.log("Demand Params");
          console.log(demandParams);

          let DemandFormElements = [];
          let demandFormData = {};

          demandParams.forEach(element => {
            let formElement = {
              'label': element.detailKey,
              'labelKey': `val_${element.detailKeyId}`,
              'required': 'true',
              'type': 'input',
            };

            let formHiddenElement = {
              'label': element.detailKey,
              'labelKey': `key_${element.detailKeyId}`,
              'hiddenValue': `${element.detailKey}`,
              'required': 'false',
              'type': 'hidden',
            };

            demandFormData[`key_${element.detailKeyId}`] = element.detailKey;
            demandFormData[`val_${element.detailKeyId}`] = findDemandDetailValue(demandDetailsData, element.detailKeyId);

            DemandFormElements.push([formElement]);
            DemandFormElements.push([formHiddenElement]);
          });

          console.log("DemandFormElements");
          console.log(DemandFormElements);

          formRef.current = {
            ...formRef.current,
            elements: DemandFormElements,
            apiMethod: 'POST',
          };

          console.log("Demand Param Form");
          console.log(formRef.current);
          console.log(demandFormData);

          setFormData(formRef.current);
          setDemandData(demandFormData);

        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [Id,isVisible]);


  return (
      demandData && isVisible ? (
        <WebForm
            id={Id}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            form={formRef.current}
            formData={demandData}
            setFormData={setDemandData}
        /> 
      ) :  null
  );
};

export default withAuthenticationRequired(DemandDetailsModal, {
  onRedirecting: () => <Loading />,
});