import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

import MessageDialog from './MessageDialog'; 
import WebInput from './WebInput';
import WebSelect from './WebSelect';
import WebTextArea from './WebTextArea';
import WebHiddenInput from './WebHiddenInput.js';
import WebInputWithSearchList from "./WebInputWithSearchList"
import WebFileUpload from "./WebFileUpload";
import Autocomplete from './AutoComplete.js';

import './WebForm.css'
import WebDatePicker from './WebDatePicker.js';
import WebInputWithDynamicSearchList from './WebInputDynamicSearchList.js';

import Loading from '../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuthContext } from '../config/AuthProvider.js';

const WebForm = ({
  id,
  isVisible,
  setIsVisible,
  form,
  formData,
  setFormData,
}) => {

  const { register, handleSubmit, setValue, reset, control } = useForm();
  const [isCustomPopupVisible, setIsCustomPopupVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [formAutoClose,setFormAutoClose] = useState(0);  

  const { accessToken } = useAuthContext();


  const handleClosePopup = () => {
    setIsCustomPopupVisible(false);
    setPopupMessage('');
    formReset();
    reset(); // Reset the form after successful submission
    formAutoClose === 1 && setIsVisible(false) ; // close the form
    setFormAutoClose(0);
  };

  useEffect(() => {
    console.log("WebForm useEffect popupMessage " + popupMessage);
    console.log(form);
    if (popupMessage) {
      setIsCustomPopupVisible(true);
    }
  }, [popupMessage]);

  const formReset = () => {
    if(formData) {
      setFormData(null);
    }
  }

  const onCancel = () => {
    console.log("On Cancel");

    formReset();
    reset();
    setIsVisible(false);
  };

const onSubmit = async (data) => {
    try {

      console.log("onSubmit data is ");
      console.log(data);
      const modifiedData = form.preHandler(data);
      console.log("onSubmit post pre handler data is ");
      console.log(data);
      if(modifiedData) {
        data = modifiedData;
        console.log("onSubmit pre handler modified data is ");
        console.log(data);
      } else {
        console.log("onSubmit pre handler didn' modify the data ");
      }


      const method = form.apiMethod;
      let url = form.restAPI + (id ? `${id}` : '');
      let contentType = form.contentType ? form.contentType : "application/json";

      const response = await axios({
        method, url, data,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': contentType,
        }
      }); 
      console.log("response code " + response.status)

      if (response.status === 200) {
        setPopupMessage('Form submitted successfully!');
        setFormAutoClose(1);
      } else {
        setPopupMessage('Error submitting form. Please try again.');
        console.log(response.data.error);
      }

      form.postHandler(response);

    } catch (error) {
      console.error(error);
      if(error.response) {
        const message = error.response.data.message;
        setPopupMessage(message);
      }else {
      setPopupMessage('An error occured. Please try again.');
      }
    }
};

  const renderWebDatePicker = (WebObj) => {
    return (
      <div key={Math.random()} className="form-col">
        <label htmlFor={WebObj.labelKey}>{WebObj.label} : </label>
        <WebDatePicker
          register={register}
          WebObj={WebObj}
          defaultValue={(formData ? new Date(formData[WebObj.labelKey]) || '' : "")}
          control={control}
        />
      </div>
    );
  }

  const renderAutocomplete = (WebObj) => {
      return (
      <div key={Math.random()} className="form-col">
        <label htmlFor={WebObj.labelKey}>{WebObj.label}</label>
        <Autocomplete
          suggestions={WebObj.options}
        />
      </div>
      );
  }

  const renderLabel = (WebObj) => {
      return (
      <div key={Math.random()} className="form-col">
        <label htmlFor={WebObj.label}>{WebObj.label} : {WebObj.labelValue}</label>
      </div>
      );
  }

  const renderFileUpload = (WebObj) => {
    return (
      <div key={Math.random()} className="form-col">
        <label htmlFor={WebObj.labelKey}>{WebObj.label}</label>
        <WebFileUpload
          register={register}
          setValue={setValue}
          WebObj={WebObj}
        />
      </div>
    );
  }


  const renderInputWithDynamicSearchList = (WebObj) => {

    return (
      <div key={Math.random()} className="form-col">
        <label htmlFor={WebObj.labelKey}>{WebObj.label}</label>
        <WebInputWithDynamicSearchList
          register={register}
          setValue={setValue}
          WebObj={WebObj}
          defaultValue={(formData ? formData[WebObj.labelKey] || '' : "")}
        />
      </div>
    );
  }

  const renderWebInputWithSearchList = (WebObj) => {

    return (
      <div key={Math.random()} className="form-col">
        <label htmlFor={WebObj.labelKey}>{WebObj.label}</label>
        <WebInputWithSearchList
          register={register}
          setValue={setValue}
          WebObj={WebObj}
          defaultValue={(formData ? formData[WebObj.labelKey] || '' : "")}
        />
      </div>
    );

  }

  const renderWebHiddenInput = (WebObj) => {
    return (
      <WebHiddenInput
        register={register}
        WebObj={WebObj}
        defaultValue={(formData ? formData[WebObj.labelKey] || '' : "")}
      />
    );
  }

  const renderWebSelect = (WebObj) => {
    return (
      <div key={Math.random()} className="form-col">
        <label htmlFor={WebObj.labelKey}>{WebObj.label} : </label>
        <WebSelect
          register={register}
          WebObj={WebObj}
          setValue={setValue}
          defaultValue={formData ? formData[WebObj.labelKey] || '' : ""}
        />
      </div>
    );
  }

  const renderWebInput = (WebObj) => {

    return (
      <div key={Math.random()} className="form-col">
        <label htmlFor={WebObj.labelKey}>{WebObj.label}</label>
        <WebInput
          register={register}
          WebObj={WebObj}
          defaultValue={formData ? formData[WebObj.labelKey] || '' : ""}
        />
      </div>
    );
  }
  
  const renderWebTextArea = (WebObj) => {
    return (
      <div key={Math.random()} className="form-col">
        <label htmlFor={WebObj.labelKey}>{WebObj.label}</label>
        <WebTextArea
          register={register}
          WebObj={WebObj}
          defaultValue={formData ? formData[WebObj.labelKey] || '' : ""}
        />
      </div>
    );
  }

  return (
    isVisible && (
      <div key="webForm" className="webform-popup">
      <div key="webFormContainer" className="webform-container" style={{ width: '50%' }}>
        <h2>{form.title}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
            {form.elements.map((row) => (
            <div key={Math.random()} className="form-row">
            {row.map( (field) => (
                field.type === 'label' ? (
                    renderLabel(field)
                ) : field.type === 'fileupload' ? (
                    renderFileUpload(field)
                ) : field.type === 'dynamicsearch' ? (
                    renderInputWithDynamicSearchList(field)
                ) : field.type === 'autocomplete' ? (
                    renderAutocomplete(field)
                ) : field.type === 'search' ? (
                    renderWebInputWithSearchList(field)
                ) : field.type === 'date' ? (
                    renderWebDatePicker(field)
                ) : field.type === 'hidden' ? (
                    renderWebHiddenInput(field)
                ) : field.type === 'select' ? (
                  renderWebSelect(field)
                ) : field.type === 'input' ? (
                    renderWebInput(field)
                  ) : field.type === 'textarea' ? (
                    renderWebTextArea(field)
                    ) : field.type === 'empty' ? (
                <div key={Math.random()} className="form-col"></div>
              ) : null 
            ) ) }
          </div>
            ) ) }
          <div key='form-row-buttons' className="form-row buttons">
            <button type="Cancel" onClick={onCancel}>
              Cancel
            </button>
            &nbsp;&nbsp;
            <button type="submit">Submit</button>
          </div>
        </form>
        <MessageDialog isVisible={isCustomPopupVisible} setIsVisible={handleClosePopup} message={popupMessage} /> {/* Pass props to CustomPopupDialog */}
      </div>
      </div>
    )
  );
};

export default withAuthenticationRequired(WebForm, {
  onRedirecting: () => <Loading />,
});