import React from 'react';
import { Form, Button } from 'react-bootstrap';

const FacetedSearchFilters = ({ register, watch, handleSubmit, reset, filterConfig, dataSet }) => {

  // Get unique values for each filter field based on the product data
  const filterValues = filterConfig.reduce((acc, filterField) => {
    acc[filterField] = [...new Set(dataSet.map((product) => product[filterField]))];
    return acc;
  }, {});

  const handleReset = () => {
    // Reset all form fields using the provided reset function
    reset();
  };

  return (
    <div className="faceted-search">
      <h4>Filters</h4>
      <Form onSubmit={handleSubmit}>
        {filterConfig.map((filterField) => (
          <Form.Group key={filterField} controlId={filterField}>
            <Form.Label>
              {filterField.charAt(0).toUpperCase() + filterField.slice(1)}
            </Form.Label>
            {filterValues[filterField].map((value) => (
              value && 
              <Form.Check
                key={value}
                type="checkbox"
                label={value}
                value={value}
                {...register(filterField)} // Register each checkbox under the filter field name
              />
            ))}
          </Form.Group>
        ))}
        <div className="filter-buttons">
          <Button variant="primary" type="submit">
            Apply Filters
          </Button>
          <Button variant="secondary" onClick={handleReset}>
            Reset All
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FacetedSearchFilters;