const contentData = [
    {
      title: "Sign In",
      link: "",
      description:
        `Sign in the Portal to starting using the ${global.AppName} SaaS Solutions`
    },
    {
      title: "Fill Account Details",
      link: "",
      description:
        "Post sign in, please fill in Account details and start utilsing the Applicant Tracking System",
    },
    {
      title: "Demand Tracking",
      link: "",
      description:
        "Utilise the ATS SaaS App to raise demands and manage the complete business workflows for sourcing the right talent",
    },
    {
      title: "Applicant Tracking",
      link: "",
      description:
        "Leverage ATS System to manage the profiles and match them up with open demands for further screening and interview process lifecycle.",
    }
  ];
  
  export default contentData;
  