
const ProfileNewFormElements = [
    [
        {
            'label': 'First Name',
            'labelKey': 'firstName',
            'required': 'true',
            'type' : 'input',
        },
        {
            'label': 'Last Name',
            'labelKey': 'lastName',
            'required': 'true',
            'type' : 'input',
        },
    ],
    [
        {
            'label': 'Email Id',
            'labelKey': 'emailId',
            'required': 'true',
            'type' : 'input',
        }
    ],
    [
        {
            'label': 'Profile / CV',
            'labelKey': 'file',
            'required': 'true',
            'type' : 'fileupload',
        }
    ],

];

export default ProfileNewFormElements;