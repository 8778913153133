import React, { useState, useEffect } from 'react';
import ResultCard from './AggregateViewCard';

const FacetedSearchAggregateResults = ({ filteredDataSet, config, groupStatusList }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = config.pagination;
  const indexCol=config.indexCol;
  const groupCol=config.groupCol;
  const gridColumns=config.gridColumns;
  const gridTableColumns=config.gridTableColumns;

  // Reset pagination on changes to filteredDataSet
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredDataSet]);

  // Group data by demandId and calculate fulfillmentStatus counts
  const groupedData = filteredDataSet.reduce((acc, curr) => {
    if (!acc[curr[indexCol]]) {
      acc[curr[indexCol]] = {
        ...curr, // Include the first item's details
        groupStatusCounts: {},
        groupedEntries: {},
      };
    }

    acc[curr[indexCol]].groupStatusCounts[curr[groupCol]] =
      (acc[curr[indexCol]].groupStatusCounts[curr[groupCol]] || 0) + 1;

      // Initialize groupedEntries array if it doesn't exist
      acc[curr[indexCol]].groupedEntries[curr[groupCol]] =
            acc[curr[indexCol]].groupedEntries[curr[groupCol]] || []; 

      acc[curr[indexCol]].groupedEntries[curr[groupCol]].push(curr);

    return acc;
  }, {});

  // Convert grouped data from object to array
  const groupedDataArray = Object.values(groupedData);

  // Calculate total number of pages (assuming all groups have one ResultCard)
  const totalPages = Math.ceil(groupedDataArray.length / itemsPerPage);

  // Get grouped data for the current page
  const paginatedData = groupedDataArray.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button key={i} onClick={() => handlePageChange(i)} disabled={i === currentPage}>
          {i}
        </button>
      );
    }
    return <div className="pagination">{pageNumbers}</div>;
  };

  return (
    <div className="search-results">
      {totalPages > 1 && renderPagination()}
      {paginatedData.map((dataItem) => (
        <div key={dataItem[indexCol]} className="data-item">
          <ResultCard 
                data={dataItem} 
                indexCol={indexCol}
                groupCol={groupCol}
                gridColumns={gridColumns}
                gridTableColumns={gridTableColumns}
                groupStatusList={groupStatusList} />
        </div>
      ))}
      {totalPages > 1 && renderPagination()}
    </div>
  );
};

export default FacetedSearchAggregateResults;