import { useEffect } from "react";

const WebFileUpload = ({register, WebObj })  => {

    useEffect(() => {
        console.log("WebFileUpload useEffect " + WebObj.labelKey )
    },[]);
    return (
            <input 
                style={{ width: '100%' }}
                type="file" 
                {...register(WebObj.labelKey , { required: WebObj.required === 'true' })}
                id={WebObj.labelKey}
                name={WebObj.labelKey}
            />
    );
};

export default WebFileUpload;