  
const PortfolioUpdateFormElements = [
    [
        {
            'label': 'Portfolio Name',
            'labelKey': 'portfolioName',
            'required': 'true',
            'type' : 'input',
        },
    ],
    [
        {
            'label': 'SPOC Name',
            'labelKey': 'spocName',
            'required': 'true',
            'type' : 'input',
        },
    ],
    [
        {
            'label': 'Remarks',
            'labelKey': 'remarks',
            'required': 'true',
            'type' : 'textarea',
        },
    ],
];

export default PortfolioUpdateFormElements;