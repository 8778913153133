import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const SortMenu = ({ sortFields, sortOption, setOption, sortOrder, setOrder }) => {

  const handleSortChange = (value) => {
    setOption(value);
  };

  const handleSortOrderChange = (value) => {
    setOrder(value);
  };

  const sortOptions = Object.keys(sortFields).map((field) => (
    <Dropdown.Item key={field} onClick={() => handleSortChange(field)}>
      {field}
    </Dropdown.Item>
  ));

  return (
    <Dropdown variant="primary">
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        Sort By: {sortOption} : {sortOrder}
      </Dropdown.Toggle>
      <Dropdown.Menu variant="primary">
        {sortOptions}
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => handleSortOrderChange('Asc')}>Asc</Dropdown.Item>
        <Dropdown.Item onClick={() => handleSortOrderChange('Desc')}>Desc</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SortMenu;