import React, {useState, useContext, useEffect} from 'react';
import DataService from "../../DataService";
import { AppConfigContext } from '../../config/AppConfig';

import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AppError from '../../../pages/AppError';
import FacetedSearch from '../../facetedsearch/FacetedSearch';
import FulfilmentStatusDataService from '../../data/FulfilmentStatusDataService';

const DemandSearchPage = () => {

    const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

    const APIEndPoint = `${URL}/demands/`;
    const [data, setData] = useState([]);
    const [statusList, setStatusList] = useState(null);
    const [dataAPICall, setDataAPICall] = useState(0);

    const facetedSearchConfig4Dummy = {
        "sort" : {
          "fields" : { 
            "Brands" : "brand", 
            "Categories" : "category"
          },
          "order" : ["Asc", "Desc"],
          "defaultField" : "Brands",
          "defaultOrder" : "Asc"
        },
        "filters" : ["brand", "category"],
        "stats" : ["brand"],
      };

    const facetedSearchConfig = {
        "title" : "Demands",
        "pagination" : 25,
        "indexCol" : "demandId",
        "groupCol" : "fulfilmentStatus",
        "search" : ["clientName","title","role","location","demandStatus",
            "fulfilmentStatus","fulfilmentSubStatus"],
        "sort": {
            "fields": {
                "Client Name": "clientName",
                "Demand Status": "demandStatus",
                "Fulfillment Status": "fulfilmentStatus",
                "Fulfilment Sub Status": "fulfilmentSubStatus",
            },
            "defaultField": "Client Name",
            "defaultOrder": "Asc"
        },
        "filters": ["clientName", "demandStatus","fulfilmentStatus"],
        "stats": ["clientName"],
        "gridColumns" : {
            "headerColumns": [
                {
                    "label": "Client Name",
                    "prop": "clientName",
                    "suffix" : ":",
                },
                {
                    "prefix" : "#",
                    "label": "Demand Id",
                    "prop": "demandId"
                },
                {
                    "label": "Title",
                    "prop": "title"
                },
                {
                    "prefix" : "(",
                    "label": "Demand Status",
                    "prop": "demandStatus",
                    "suffix" : ")"
                },
                {
                    "prefix" : "-",
                    "label": "Start Date",
                    "prop": "startDate"
                },
            ],
            "dataColumns": [
                {
                    "label": "Role",
                    "prop": "role"
                },
                {
                    "label": "Location",
                    "prop": "location"
                },
                {
                    "label": "No Of Positions",
                    "prop": "positions"
                },
                { 
                    "label": "Delivery SPOC",
                    "prop": "deliverySPOC"
                },
                {
                    "label": "Sales SPOC",
                    "prop": "salesSPOC"
                },
                {
                    "label": "Talent SPOC",
                    "prop": "talentSPOC"
                },
                {
                    "label": "Client Ref ID",
                    "prop": "clientRefCode"
                },
                {
                    "label": "Demand Ref ID",
                    "prop": "demandRefCode"
                },
                {
                    "label": "Hiring Ref ID",
                    "prop": "hiringRefCode"
                },
                {
                    "label": "Portfolio Name",
                    "prop": "portfolioName"
                },
                {
                    "label": "Program Name",
                    "prop": "programName"
                },
                {
                    "label": "Project Name",
                    "prop": "projectName"
                },
            ],
        },
        "tableColumns": [
            {
                "label": "Client Name",
                "prop": "clientName"
            },
            {
                "label": "Demand Id",
                "prop": "demandId"
            },
            {
                "label": "Title",
                "prop": "title"
            },
            {
                "label": "Role",
                "prop": "role"
            },
            {
                "label": "Location",
                "prop": "location"
            },
            {
                "label": "No Of Positions",
                "prop": "positions"
            },
            {
                "label": "Start Date",
                "prop": "startDate"
            },
            {
                "label": "Demand Status",
                "prop": "demandStatus"
            },
            {
                "label": "Fulfilment Status",
                "prop": "fulfilmentStatus"
            },
            {
                "label": "Fulfilment Sub Status",
                "prop": "fulfilmentSubStatus"
            },
            {
                "label": "Delivery SPOC",
                "prop": "deliverySPOC"
            },
            {
                "label": "Sales SPOC",
                "prop": "salesSPOC"
            },
            {
                "label": "Talent SPOC",
                "prop": "talentSPOC"
            },
            {
                "label": "Client Ref ID",
                "prop": "clientRefCode"
            },
            {
                "label": "Demand Ref ID",
                "prop": "demandRefCode"
            },
            {
                "label": "Hiring Ref ID",
                "prop": "hiringRefCode"
            },
            {
                "label": "Candidate First Name",
                "prop": "firstName"
            },
            {
                "label": "Candidate Last Name",
                "prop": "lastName"
            },
            {
                "label": "Email ID",
                "prop": "emailId"
            },
            {
                "label": "Portfolio Name",
                "prop": "portfolioName"
            },
            {
                "label": "Program Name",
                "prop": "programName"
            },
            {
                "label": "Project Name",
                "prop": "projectName"
            },
        ],
        "gridTableColumns": [
            {
                "label": "Fulfilment Id",
                "prop": "fulfilmentId"
            },
            {
                "label": "Candidate First Name",
                "prop": "firstName"
            },
            {
                "label": "Candidate Last Name",
                "prop": "lastName"
            },
            {
                "label": "Email ID",
                "prop": "emailId"
            },
            {
                "label": "Fulfilment Status",
                "prop": "fulfilmentStatus"
            },
            {
                "label": "Fulfilment Sub Status",
                "prop": "fulfilmentSubStatus"
            },
        ]
    };

    const {accessToken} = useAuthContext();
    const [state, setState] = useState({
        showResult: false,
        loading: true,
        error: null,
      });

      const getStatusOptions = async() => {
        const statusOptions = await FulfilmentStatusDataService.getAllStatus(accessToken);
        setStatusList(statusOptions);
     }

     const getLatestList = async () => {
        // Update data based on new submission (e.g., fetch new data or add new client)
        console.log("getLatestList started");
        try {
            const response = await DataService.getAll(APIEndPoint, accessToken);
            if (!response) {
                const AppErrorMessage = {
                    "status": 401,
                    "error": "Request failed",
                    "message": "Requested details not available"
                };
                setData(null);
                setState({
                    ...state,
                    showResult: false,
                    loading: false,
                    error: AppErrorMessage,
                });
            } else {

                let demandList = response.data;
                demandList.map(demandItem => {
                    demandItem.id = demandItem.demandId;
                });

                setData(demandList);
                console.log("Demand List is ");
                console.log(data);
                setState({
                    ...state,
                    showResult: true,
                    loading: false,
                    error: null,
                });
            }
        }
        catch (error) {
            console.log("Demand list Data fetch error");
            const AppErrorMessage = {
                "status": error.status,
                "error": error.response.data.error,
                "message": error.response.data.message
            };
            console.log(AppErrorMessage);
            setData(null);
            setState({
                ...state,
                showResult: false,
                loading: false,
                error: AppErrorMessage
            });
        }
        console.log("getLatestList completed");
    };
    
    useEffect(() => {

        console.log("Demands Faceted Search Page useEffect started : " + dataAPICall )
        getStatusOptions();
        getLatestList();
        console.log("Demands Faceted Search Page useEffect completed : " + dataAPICall)


    }, []);

        return (
            <>
             {state.loading && (
                    <div> <Loading /> </div>
                )}
                {state.error && (
                    <div>
                        <AppError 
                            headerTitle={`App Error - ${state.error.status}`} 
                            messageTitle={state.error.error}
                            message={state.error.message}
                        />
                    </div>
                )}
            {state.showResult && (
            <div>
                <FacetedSearch
                        data={data}
                        statusList={statusList}
                        config={facetedSearchConfig}
                    />
            </div>
            )}
            </>
        );
}

export default withAuthenticationRequired(DemandSearchPage, {
    onRedirecting: () => <Loading />,
  });