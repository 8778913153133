import React, { useEffect, useContext, useState } from 'react';
import DataService from '../../DataService';
import WebForm from '../../html/WebForm';
import UpdateFormElements from './DemandProgramUpdateFormElements';
import { AppConfigContext } from '../../config/AppConfig';

import { useAuthContext } from '../../config/AuthProvider';
import Loading from '../../../pages/components/Loading';
import { withAuthenticationRequired } from "@auth0/auth0-react";

const DemandProgramUpdateModal = ({ isVisible, setIsVisible, Id, onFresh, demandData, portfolioList, programList, projectList }) => {

  const AppConfig = useContext(AppConfigContext);
	const URL = AppConfig.ATS_API_HOST + ":" + AppConfig.ATS_API_PORT + AppConfig.ATS_API_ENDPOINT;

  const [modalData, setModalData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState();

  const DemandProgramUpdateAPIEndPoint = `${URL}/demandpf/`;
  const {accessToken} = useAuthContext();

  const preFormSubmission = (data) => {
    console.log("preFormSubmission");
};

const postFormSubmission = (response) => {
    console.log("postFormSubmission");
    onFresh();
}

const Form = {
    'title' : 'Demand Portfolio Details',
    'restAPI' : DemandProgramUpdateAPIEndPoint,
    'apiMethod' : 'PUT',
    'elements' : UpdateFormElements,
    'preHandler' : preFormSubmission,
    'postHandler' : postFormSubmission
};

  const setPortfolioDependentOn = (value) => {

    console.log("Portfolio Callback dependent list for value " + value);

    if (programList) {

      console.log("Program Full List ", programList);
      let portfolioId = value;
      let programId = programList[value][0].value;
      let projects = projectList[programId];

      console.log("Program Filtered List ", programList[portfolioId]);
      console.log("Project Filtered List ", projects);

      UpdateFormElements[1][0] = {
        ...UpdateFormElements[1][0],
        "options": programList[value],
      };

      UpdateFormElements[2][0] = {
        ...UpdateFormElements[2][0],
        "options": projects,
      };

      setSelectedStatus(value);
    }

  }

  const setProgramDependentOn = (value) => {

    console.log("Program Callback dependent list for value " + value);
    if (projectList) {
      console.log("Full Project List ", projectList);
      console.log("Filtered Project List ", projectList[value]);

      UpdateFormElements[2][0] = {
        ...UpdateFormElements[2][0],
        "options": projectList[value],
      };

      setSelectedStatus(value);
    }
  }

  useEffect(() => {

    if (portfolioList) {
      console.log("pfList is available ", portfolioList);
      UpdateFormElements[0][0] = {
        ...UpdateFormElements[0][0],
        "options": portfolioList,
        "callback": setPortfolioDependentOn,
      };

    } else {
      console.log("pfList is not available ", portfolioList);
    }

    setSelectedStatus(true);
  }, [portfolioList]);

  useEffect(() => {

    console.log("pgmList useEffect ", programList);
    UpdateFormElements[1][0] = {
      ...UpdateFormElements[1][0],
      "options": "",
      "callback": "",
    };
    setSelectedStatus(false);

    if (programList && portfolioList) {

        let defaultPortfolioId = demandData.portfolioId;
        if(!defaultPortfolioId && portfolioList.length > 0) {
          defaultPortfolioId = portfolioList[0].value;
        }

        if (defaultPortfolioId) {
          console.log("Portfolio ID  for default Program options is ", defaultPortfolioId);
          console.log("Default Program options are ", programList[defaultPortfolioId]);

          UpdateFormElements[1][0] = {
            ...UpdateFormElements[1][0],
            "options": programList[defaultPortfolioId],
            "callback": setProgramDependentOn,
          };
        } 

      setSelectedStatus(defaultPortfolioId);
    }


  }, [programList]);

  useEffect(() => {

    console.log("prList useEffect", projectList);
    UpdateFormElements[2][0] = {
      ...UpdateFormElements[2][0],
      "options": "",
    };
    setSelectedStatus(false);

    if (projectList && programList) {
      let defaultProgramId = demandData.programId;

      if (!defaultProgramId &&
        Object.keys(programList).length > 0) {
        console.log("Program list keys ", Object.keys(programList));
        console.log("Program list keys length ", Object.keys(programList).length);
        console.log("Program list first entry ", Object.keys(programList)[0]);
        defaultProgramId = programList[Object.keys(programList)[0]][0].value;
      } 

      console.log("Default Program Id is ", defaultProgramId);

      if (defaultProgramId) {
        console.log("Program ID  for default Project options is ", defaultProgramId);
        console.log("Default Project options are ", projectList[defaultProgramId]);
        UpdateFormElements[2][0] = {
          ...UpdateFormElements[2][0],
          "options": projectList[defaultProgramId],
        };
      }

      setSelectedStatus(defaultProgramId);
    }
    else {
      console.log("pgmList or prList is empty ", programList);
    }

  }, [projectList]);

  
  useEffect(() => {
      console.log("demandData Useeffect")
      console.log(demandData);
      console.log(Form);
    if (isVisible) {
      setModalData(demandData)
    }
  }, [Id,isVisible]);
  


  return (
      modalData && isVisible ? (
        <WebForm
            id={Id}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            form={Form}
            formData={modalData}
            setFormData={setModalData}
        /> 
      ) :  null
  );
};

export default withAuthenticationRequired(DemandProgramUpdateModal, {
  onRedirecting: () => <Loading />,
});