import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

const FacetedSearchTableResults = ({ filteredDataSet, config}) => {
  const columns = config.tableColumns;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = config.pagination;

  // Calculate total number of pages
  const totalPages = Math.ceil(filteredDataSet.length / itemsPerPage);

  // Get products for the current page
  const paginatedDataSet = filteredDataSet.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button key={i} onClick={() => handlePageChange(i)} disabled={i === currentPage}>
          {i}
        </button>
      );
    }
    return <div className="pagination">{pageNumbers}</div>;
  };

  return (
    <div>
      {totalPages > 1 && renderPagination()}
      <Table responsive striped bordered hover >
        <thead>
          <tr>
            {/* Generate table headers from columns prop */}
            {columns.map((column) => (
              <th key={column.label} style={{ whiteSpace: 'nowrap' }}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginatedDataSet.map((dataItem) => (
            <tr key={`${dataItem.id}${dataItem.fulfilmentId}`}>
              {/* Access data using props from columns */}
              {columns.map((column) => (
                <td key={column.prop} style={{ whiteSpace: 'nowrap' }}>{dataItem[column.prop]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {totalPages > 1 && renderPagination()}
    </div>
  );
};

export default FacetedSearchTableResults;